import React from 'react';
import { useLocation } from 'react-router-dom';
import MetaBanner from '../components/MetaBanner';

const BannerDetail = () => {
  const location = useLocation();
  const { src, href, title } = location.state || {};  // 从 location.state 获取数据 style={{ backgroundImage: `url(${src})`, }}

  return (
    <div className="h-screen flex flex-col bg-contain	bg-no-repeat justify-center items-center" style={{ backgroundImage: `url(${src})` }}>
      <MetaBanner href={href} />
      <h1 className="text-6xl font-bold text-gray-800 my-8">正在进入</h1>
      <p className="text-2xl font-medium text-gray-800">{title}</p>
      <a href={`${href}`} className="w-28 mx-auto flex items-center justify-center rounded-md border px-4 py-2 text-base font-bold mt-6">直接进入</a>
    </div>
  );
};

export default BannerDetail;