import React, { useEffect, useState } from 'react';
import Meta from '../components/Meta';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { NativeAds } from '../components/AdsComponent';
import { LazyImage, placeholder, errorPlaceholder } from '../components/LazyImage';
import ErrorRetry, { LoadingText } from '../components/ErrorRetry';

const Home = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  
  const fetchData = async () => {

    setLoading(true); // 开始加载
    setError(false); // 重置错误状态

  try {

    await new Promise(resolve => setTimeout(resolve, 500));

    const response = await axios.get('/home');
    if (response.data.status === 200) {
      const groupedData = response.data.data.reduce((acc, item) => {
        (acc[item.typename] = acc[item.typename] || []).push(item);
        return acc;
      }, {});
      setData(groupedData);
      } else {
        console.error('Failed to fetch Home data:', response.data);
        setError(true);
      }
    } catch (error) {
      console.error('Error fetching Home data:', error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return (
      <LoadingText />
    );
  }

  if (error) {
    return (
      <ErrorRetry title={'首页更新'} onClick={fetchData} />
    );
  }

  return (
    <>
      <Meta title="首页-5G影院" description="欢迎来到5G影院，这里有最精彩的在线视频，每天更新永久免费" keywords="5G影院, 首页, 在线视频, 每天更新, 永久免费" />

      {Object.keys(data).map((category) => {
        const items = data[category];
        const randomIndex = Math.floor(Math.random() * (items.length + 1));

        const itemsWithAd = [
          ...items.slice(0, randomIndex),
          <NativeAds key={`native-ad-${category}`} />,
          ...items.slice(randomIndex),
        ];

        return (
          <div key={category}>
            <div className="title bg-tw text-tb p-2 md:p-4 text-left mb-4">
              <Link to={`/h/${category}`}>{category}</Link> 最新上架
            </div>
            <div className="content columns-2 xs:columns-1 s:columns-2 sm:columns-3 md:columns-4 lg:columns-4 gap-2 mb-4">
              {itemsWithAd.map((item, index) => (
                <div key={item.id || `ad-${category}-${index}`} className="break-inside-avoid mb-4">
                  {React.isValidElement(item) ? (
                    item
                  ) : (
                    <Link to={`/${item.id}`} className="break-inside-avoid mb-4">
                      <LazyImage
                        className="w-full h-auto object-cover"
                        src={item.litpic}
                        alt={item.title}
                        placeholder={placeholder(300, 200)}
                        errorPlaceholder={errorPlaceholder(300, 200, item.title)}
                        width={300}
                        height={200}
                      />
                      <p className="text-center mt-2 text-sm">{item.title}</p>
                    </Link>
                  )}
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Home;
