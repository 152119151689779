import { useNavigate } from 'react-router-dom';

const ErrorRetry = ({title, onClick}) => {
    const navigate = useNavigate();

    return (
    <div className="h-screen flex flex-col justify-center items-center w-full my-4">
        <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-xl leading-6 font-bold my-6">
                {title}加载错误
            </h3>
            <div className="mt-2 text-sm font-thin">
                <p className="mb-2">可能是内容不存在，请</p>
            </div>
            <button className="w-28 bg-tw text-tb mx-auto flex items-center justify-center rounded-md px-4 py-2 text-base font-bold my-6"
            onClick={() => navigate(-1)}>
                返回
            </button>
            <div className="mt-2 text-sm font-thin">
                <p className="mb-2">若有网络问题</p>
                <p className="mb-2">检查网络是否正常</p>
                <p className="mb-2">网址被举报被所有网络封锁</p>
                <p className="mb-2">可以的话，使用VPN翻墙即可正常访问</p>
                <p className="mb-2">当地网络屏蔽成人内容，考虑使用电信联通</p>
                <p className="mb-2">使用的域名较老，请发邮件到找回邮箱获取最新域名</p>
            </div>
            <button className="w-28 mx-auto flex items-center justify-center rounded-md border px-4 py-2 text-base font-bold my-6"
            onClick={onClick}>
                重试
            </button>
        </div>
    </div>);
};

export const ErrorRetryOnlyBtn = ({ onClick }) => {
    return (
        <button className="w-28 mx-auto flex items-center justify-center rounded-md border px-4 py-2 text-base font-bold my-6"
        onClick={onClick}>
            重试
        </button>
    );
};

export const LoadingText = () => (
    <div className="banner flex flex-col justify-center items-center min-h-[700px] w-full my-4">
        &nbsp;&nbsp;&nbsp;&nbsp;加载中...
    </div>
);

export const NoDetail = ({ message }) => {
    const navigate = useNavigate();
  
    return (
        <div className="h-screen flex flex-col justify-center items-center">
          <h1 className="text-8xl font-bold text-gray-800 my-8">404</h1>
          <p className="text-4xl font-medium text-gray-800">{message}</p>
          <button className="w-28 mx-auto flex items-center justify-center rounded-md border px-4 py-2 text-base font-bold mt-6" onClick={() => navigate(-1)}>点击返回</button>
        </div>
    );
};

export default ErrorRetry;