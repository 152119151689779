import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import NoHeaderFooterLayout from './layouts/NoHeaderFooterLayout';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import CategoryList from './pages/CategoryList';
import BannerDetail from './pages/BannerDetail';
import Detail from './pages/Detail';
import Tags from './pages/Tags';
import Series from './pages/Series';

const App = () => {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path="*" element={<DefaultLayout />}>
            <Route index element={<Home />} />
            <Route path=":id" element={<Detail />} />
            <Route path=":basePath/:typename/:page?" element={<CategoryList />} />
            <Route path="tags" element={<Tags />} />
            <Route path="series" element={<Series />} />
          </Route>
          <Route path="b/:id" element={<NoHeaderFooterLayout />}>
            <Route index element={<BannerDetail />} />
          </Route>
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

const DefaultLayout = () => (
  <div className="default-layout">
    <Header />
    <main className="mb-4 min-h-[530px]">
      <Routes>
        <Route index element={<Home />} />
        <Route path=":id" element={<Detail />} />
        <Route path=":basePath/:typename/:page?" element={<CategoryList />} />
        <Route path="tags" element={<Tags />} />
        <Route path="series" element={<Series />} />
      </Routes>
    </main>
    <Footer />
  </div>
);

export default App;
