import React from 'react';
import { Link } from 'react-router-dom';
import { LazyImage, placeholder, errorPlaceholder } from './LazyImage';

const ThumberList = ({ data }) => {
  return (
      <div className="content columns-2 xs:columns-1 s:columns-2 sm:columns-3 md:columns-4 lg:columns-4 gap-2 mb-4">
        {data.map((item) => (
          <div key={item.id} className="break-inside-avoid mb-4">
            <Link to={`/${item.id}`} className="break-inside-avoid mb-4">
            {item.litpic && item.litpic.length > 0 ? (
              <>
              <LazyImage
                className="w-full h-auto object-cover"
                src={item.litpic}
                alt={item.title}
                placeholder={placeholder(300, 200)}
                errorPlaceholder={errorPlaceholder(300, 200, item.title)}
                width={300}
                height={200}
              />
              <p className="text-center mt-2 text-sm">{item.title}</p>
              </>
            ) : (
              <p className="text-center bg-tb my-1 px-3 py-5 text-sm">{item.title}</p>
            )}
            </Link>
          </div>
        ))}
      </div>
  );
};

export default ThumberList;
